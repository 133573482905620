import { AlertCircle } from '@styled-icons/feather';
import { Icon, IconProps } from './Icon';

export function AlertIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <AlertCircle />
    </Icon>
  );
}
